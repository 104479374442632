import React from "react"
import { graphql } from "gatsby"
import styled from "styled-components"
import {
  Spacer,
  Section,
  Avatar,
  Loader,
  Image,
  DropButtons,
  Checkbox,
} from "@bw/bits"
import { NftTeaser } from "@bw/partials"
import { Search } from "@bw/icons"
import { useNfts } from "@contexts/nft"
import { Layout } from "@bw/layouts"
import { mediaQuery } from "../theme"
import { useQuery } from "react-query"
import { Input, FormField } from "@bw/forms"
import { Formik, Form, Field } from "formik"
import { useTranslation } from "react-i18next"
import { useLocalization } from "gatsby-theme-i18n"
import { InfiniteScroll } from "grommet"
import axios from "axios"

const initialValues = {
  is_nsfw: false,
  search: "",
  min_price: "",
  max_price: "",
  sortedBy: "listed_at_desc",
  trade: "listed",
  tags: [],
}

const ExploreNfts = ({ data, location, pageContext }) => {
  const { t } = useTranslation()
  const { locale, localizedPath, defaultLang } = useLocalization()
  const [listedNfts] = useNfts()
  const [filter, setFilter] = React.useState(initialValues)
  const allCollections = data.allCollection.nodes
  const initialSortedBy = [
    {
      active: true,
      value: "listed_at_desc",
      name: t("sortedBy.recent"),
    },
    {
      active: false,
      value: "listed_at_asc",
      name: t("sortedBy.oldest"),
    },
    {
      active: false,
      value: "price_desc",
      name: t("sortedBy.htl"),
    },
    {
      active: false,
      value: "price_asc",
      name: t("sortedBy.lth"),
    },
  ]
  const [sortedByList, setSortedByList] = React.useState(initialSortedBy)

  let timer

  const {
    data: allNfts = { tokens: [], nfts: { listed: [], unlisted: [] } },
    isLoading,
  } = useQuery(["all_nft", listedNfts], () => {
    return axios
      .get(`/api/token`)
      .then(response => response.data.data)
      .then(response => {
        // find listed items
        const nfts = response.reduce(
          (acc, nft) => {
            const listedNft = listedNfts.listed.find(
              listed => listed.nft === nft.address
            )

            if (listedNft) {
              acc.listed.push({
                ...nft,
                price: listedNft.price,
                listed_at: listedNft.listed_at,
              })
            } else {
              acc.unlisted.push(nft)
            }

            return acc
          },
          { listed: [], unlisted: [] }
        )

        return {
          tokens: response,
          nfts,
        }
      })
  })

  const tags = React.useMemo(() => {
    const { tags } = allCollections.reduce(
      (acc, collection) => {
        if (!collection.tags) {
          return acc
        }

        for (let i = 0; i < collection.tags.length; i++) {
          if (!acc.keys.includes(collection.tags[i].slug)) {
            const { slug, title } = collection.tags[i]
            acc.keys.push(slug)
            acc.tags.push({
              value: slug,
              name: title,
              active: false,
            })
          }
        }

        return acc
      },
      { keys: [], tags: [] }
    )

    tags.sort((a, b) => a.name.localeCompare(b.name))

    return tags
  }, [allCollections])

  const results = React.useMemo(() => {
    if (isLoading || !allNfts.nfts) {
      return []
    }

    let nfts =
      filter.trade === "not_listed"
        ? allNfts.nfts.unlisted
        : allNfts.nfts.listed

    if (filter.search.length > 2) {
      nfts = nfts.filter(nft =>
        nft.name.toLowerCase().includes(filter.search.toLowerCase())
      )
    }

    if (filter.is_nsfw === false) {
      nfts = nfts.filter(nft => !nft.collection.is_nsfw)
    }

    if (filter.tags.length > 0) {
      nfts = nfts.filter(nft => {
        const collectionTags = nft.collection.tags.map(t => t.slug)
        for (let i = 0; i < collectionTags.length; i++) {
          if (filter.tags.includes(collectionTags[i])) {
            return true
          }
        }

        return false
      })
    }

    if (filter.trade === "not_listed") {
      // we return at this point, there is no other possibilty of
      // filtering or sorting
      return nfts
    }

    /**
     * Sorting
     */
    switch (filter.sortedBy) {
      case "price_desc":
        nfts.sort((a, b) => b.price - a.price)
        break

      case "price_asc":
        nfts.sort((a, b) => a.price - b.price)
        break

      case "listed_at_asc":
        nfts.sort((a, b) => a.listed_at - b.listed_at)
        break

      case "listed_at_desc":
      default:
        nfts.sort((a, b) => b.listed_at - a.listed_at)
    }

    return nfts
  }, [filter, allNfts, isLoading])

  const isShowingAllNfts = allNfts.tokens.length === results.length
  const subtitle = isShowingAllNfts
    ? t("nft.explore.showingAllXNfts", {
        total: allNfts.tokens.length,
      })
    : t("nft.explore.showingXofYNfts", {
        found: results.length,
        total: allNfts.tokens.length,
      })

  return (
    <Layout {...{ pageContext }}>
      <Section
        containerSize="large"
        title={t("nft.explore.title")}
        subtitle={isLoading ? null : subtitle}
      />
      {isLoading ? (
        <Loader
          messages={[
            t("loading.userNfts"),
            t("loading.itTakesLongerThanExpected"),
          ]}
          treshold={20}
        />
      ) : (
        <>
          <Section containerSize="large">
            <Formik
              initialValues={initialValues}
              onSubmit={values => {
                setFilter(values)
              }}
            >
              {({ submitForm, setFieldValue }) => (
                <Form
                  onChange={() => {
                    clearTimeout(timer)
                    timer = setTimeout(submitForm, 500)
                  }}
                >
                  <Spacer direction="row" align="center">
                    <div style={{ width: "320px" }}>
                      <FormField name="search">
                        <Field
                          name="search"
                          type="text"
                          component={Input}
                          icon={<Search />}
                          placeholder={t("search")}
                        />
                      </FormField>
                    </div>
                    <FormField name="category">
                      <DropButtons
                        label={t("category")}
                        onChange={list => {
                          setFilter({
                            ...filter,
                            tags: list.filter(t => t.active).map(t => t.value),
                          })
                        }}
                        categories={tags}
                      />
                    </FormField>
                    <FormField name="sortedBy">
                      <Field
                        name="sortedBy"
                        subtitle={t("sortedBy.subtitle")}
                        component={DropButtons}
                        multiple={false}
                        onChange={x => {
                          setFieldValue("sortedBy", x.find(y => y.active).value)
                          submitForm()
                          setSortedByList(x)
                        }}
                        categories={sortedByList}
                        stateless
                      />
                    </FormField>
                    <FormField name="trade">
                      <Field
                        name="trade"
                        label="Selling"
                        subtitle={t("sortedBy.trade")}
                        component={DropButtons}
                        multiple={false}
                        onChange={x => {
                          setFieldValue("trade", x.find(y => y.active).value)
                          submitForm()
                        }}
                        categories={[
                          {
                            active: true,
                            value: "listed",
                            name: t("listed"),
                          },
                          {
                            active: false,
                            value: "not_listed",
                            name: t("unlisted"),
                          },
                        ]}
                      />
                    </FormField>
                    <FormField name="is_nsfw">
                      <Field
                        name="is_nsfw"
                        label={t("collection.explore.is_nsfw")}
                        component={Checkbox}
                      />
                    </FormField>
                  </Spacer>
                </Form>
              )}
            </Formik>
          </Section>
          <Section containerSize="large">
            {results.length === 0 && filter !== initialValues ? (
              <div>{t("noResults")}</div>
            ) : (
              <Grid>
                <InfiniteScroll items={results} step={9}>
                  {item => (
                    <NftTeaser
                      key={item.address}
                      to={localizedPath({
                        defaultLang,
                        locale,
                        path: `/nft/${item.address}/`,
                      })}
                      state={{ from: location.pathname }}
                      name={item.name}
                      likes={item.likes_count}
                      artist={item.collection.name}
                      price={item.price}
                      image={
                        <Image
                          type="NFT"
                          avatar={<Avatar image={item.collection.icon_url} />}
                          image={`${process.env.GATSBY_API_URL}/images/${item.image}`}
                        />
                      }
                    />
                  )}
                </InfiniteScroll>
              </Grid>
            )}
          </Section>
        </>
      )}
    </Layout>
  )
}

export default ExploreNfts

export const exploreNftsQuery = graphql`
  query exploreNftsQuery {
    allCollection(sort: { fields: statamicId, order: DESC }) {
      nodes {
        id
        name
        symbol
        is_nsfw
        square_teaser_url
        icon_url
        likes_count
        tags {
          slug
          title
        }
      }
    }
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 25px;

  ${mediaQuery("small")`
  grid-gap: 45px;
  `};

  ${mediaQuery("medium")`
    grid-template-columns: 1fr 1fr 1fr;
  `};

  ${mediaQuery("large")`
    grid-template-columns: 1fr 1fr 1fr 1fr;
  `};
`
